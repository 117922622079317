import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useState, useRef } from "react";
import { BrowserRouter, Link, Routes, Route } from "react-router-dom";
import Button from "../components/Button";
import Header from "../components/Header";
import Input from "../components/Input";
import Logo from "../components/Logo";
import { AuthContext } from "../providers/AuthProvider";
import { ConfigContext } from "../providers/ConfigProvider";
import Register from "../screens/Register";
import ForgotPassword from "./ForgotPassword";
import ReactGA from 'react-ga4';

export default function AuthDashboard(){
    
    if(ReactGA.isInitialized){
        ReactGA.send({ hitType: "pageview", page: "/din-side", title: "Innlogging" });
    }
    return (
        <BrowserRouter>
            <Routes>
                <Route exact path="/glemtpassord" element={<ForgotPassword />}></Route>
                <Route exact path="/registrer" element={<Register />}></Route>
                <Route path="*" element={<Login />}></Route>
            </Routes>
        </BrowserRouter>
    )
}

function Login(){
    const [loading, setLoading] = useState(false)
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [error, setError] = useState({})

    const { login } = useContext(AuthContext)
    const { data, lingo } = useContext(ConfigContext)
    


    const doLogin = async () => {
        setLoading(true);
        try{
            login(email, password).then((status) => {
                if(status.data.status !== 1){
                    setLoading(false);
                    setError({status: status.data.status, msg: status.data.message})
                }
            })
        }catch(e){
            setLoading(false);
            setError({status: null, msg: "Fikk ikke kontakt med serveren. Vennligst prøv igjen senere."})
        }
    }
    useEffect(()=>{
        const listener = (e) => {
            if(e.code === "Enter" || e.code === "NumpadEnter"){
                e.preventDefault();
                doLogin();
            }else{
                setError({})
            }
        }
        document.addEventListener("keydown", listener);
        return () => {
            document.removeEventListener("keydown", listener)
        }
    })

    return (
        <div className="minheight-full position-relative">
            
            <div className="d-lg-flex d-none no-gutters h-100 w-100 position-absolute cover">
                <div className="col-6 bg-light-green opacity-heavy">
                </div>
            </div>
                
            <div className="px-lg-5 px-3 w-100 h-100 position-relative login-container">
                <div className="d-flex flex-wrap h-100 align-items-center">
                    <div className="col-lg-6 text-lg-center">
                        <div className="login-left-wrap pt-4 pt-lg-0">
                            <div className="d-flex flex-wrap d-lg-block">

                                <div className="order-1">
                                    <h3>{data && data.login_title ? `${data.login_title}` : (data && data.short_name ? `${data.short_name}s søknadsportal` : lingo.portal_of_applications)}</h3>
                                    <p>{data && data.login_lead ? data.login_lead : lingo.login_lead}</p>
                                    <p>{lingo.login_call_to_action}</p>
                                </div>
                                <div className="order-0">
                                    <img src={process.env.PUBLIC_URL + '/gfx/login-gfx.svg'} className="mt-5 mb-5 mb-lg-0 login-gfx d-block mx-auto" alt="Søknadsportalen grafikk" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 pb-5 ps-lg-5 col-12">
                        <div className="login-wrap">
                            <h2 className="py-3">{lingo.login_title}</h2>
                            <div className="form-group mb-3">
                                <label>{lingo.login_email_label}</label>
                                <Input 
                                    type="email"
                                    placeholder={lingo.login_email_placeholder}
                                    value={email}
                                    rules={[]}
                                    onChange={(value) => setEmail(value)}
                                />
                            </div>
                            <div className="form-group mb-3">
                                <label>{lingo.login_password_label}</label>

                                <Input 
                                    type="password"
                                    placeholder={lingo.login_password_placeholder}
                                    value={password}
                                    rules={[]}
                                    onChange={(value) => setPassword(value)}
                                />
                            </div>
                            {error ? <p className="text-error">{error.msg}</p> : null}
                            <Button 
                                title={lingo.login_button_title}
                                type="primary"
                                disabled={loading}
                                loadingIndicator={loading}
                                onClick={doLogin}
                                classes="mb-5"
                            />
                            <p className="mb-2">{lingo.login_forgot_password_title} <Link to="/glemtpassord">{lingo.login_forgot_password_link_text}</Link></p>
                            <p>{lingo.login_first_time_text} <Link to="/registrer">{lingo.login_register_new_user_link_text}</Link> {lingo.login_register_new_user_text}</p>
                            <Logo className={"login-logo"} />
                        </div>

                    </div>
                </div>
             </div>
        </div>
    )
}