import React, { useContext, useEffect, useState } from "react";
import config from '../config.json';
import axios from "axios";
import ReactGA from 'react-ga4';


export const ConfigContext = React.createContext({
    data: null,
    typeBook: null,
    hasFinalReport: () => {}
})

export const ConfigProvider = ({children}) => {
    const [data, setData] = useState(null)
    const [typeBook, setTypeBook] = useState(null);
    const [contextKey, setContextKey] = useState(null);
    const [baseURL, setBaseURL] = useState(null);
    const [lingo, setLingo] = useState(null);

    useEffect(() => {

        // Må avtale noen kjøreregler for å finne ut hvor api'et ligger til enhver tid basert på window.location.hostname

        if(window.location.hostname === 'localhost' || window.location.hostname === 'soknadsportal.catchmedia.no'){
            setBaseURL('https://sbsdev.catchmedia.no/');
        } else if (window.location.hostname === 'soknadsportal.sparebankstiftelsenbien.no'){
            setBaseURL('https://api.sparebankstiftelsenbien.no/');
        } else if (window.location.hostname === 'soknadsportal.nomestiftelsen.no'){
            setBaseURL('https://api.nomestiftelsen.no/');
        } else if (window.location.hostname === 'soknadsportal.aurskog-sparebank.no') {
            setBaseURL('https://api.aurskog-sparebank.no/');
        } else if (window.location.hostname === 'soknadsportal.sparebankstiftingass.no'){
            setBaseURL('https://api.sparebankstiftingass.no/');
        } else if (window.location.hostname === 'soknadsportal.sparebank1stiftelsenjln.no'){
            setBaseURL('https://api.sparebank1stiftelsenjln.no/');
        } else if (window.location.hostname === 'soknadsportal.skagerraksparebank.no') {
            setBaseURL('https://api.skagerraksparebank.no/');
        } else {
            // splitte ting og finne fra www.ringerikebs.no så skal vi sitte igjen med ringerikebs.no
            // setBaseURL('https://www' + baseURL + '/');
            //setBaseURL(res);
            let hostname = window.location.hostname;
            let splitted = hostname.split('.');

            if(splitted[0] === 'soknadsportal'){
                // Kun for sider som har www.stiftelse.no/api/
                // hostname = soknadsportal.stiftelse.no
                setBaseURL(`https://www.${splitted[1]}.${splitted[2]}/`);
            }else{
                // For andre sider som har vårt api på subdomene
                // hostname = [alt annet enn soknadsportal og www].stiftelse.no
                setBaseURL(`https://soknad.${splitted[1]}.${splitted[2]}/`);
            }
        }
    },[])

    useEffect(() => {
        if(baseURL){
            axios.defaults = Object.assign(axios.defaults, {
                withCredentials: true,
                baseURL: baseURL,
            });
        }
        
    }, [baseURL])

    useEffect(()=>{
        if(baseURL){
            fetchConfig()
        }
    },[baseURL])

    useEffect(() => {
        if(data){
            initGA();
            fetchMultilingual();
            setTypeBook(configTypeBook);
            if(baseURL && data.custom_css){
                // Add custom stylesheet to the app from the baseURL
                const link = document.createElement('link');
                link.rel = 'stylesheet';
                link.type = 'text/css';
                link.href = `${baseURL}${data.custom_css}`;
                document.head.appendChild(link);
            }
            if(baseURL && data.custom_favicon){
                const head = document.head || document.getElementsByTagName('head')[0];
                
                let existing_favicon = head.querySelector('[rel="icon"]');
                existing_favicon.href = baseURL + data.custom_favicon;

                existing_favicon = head.querySelector('[rel="apple-touch-icon"]');
                existing_favicon.href = baseURL + data.custom_favicon;
            }
            if(baseURL && data.title){
                let existing_title = document.title;
                document.title = existing_title + ' - ' + data.title;
            }
        }
    }, [data]);

    const fetchMultilingual = async () => {
        try{
            const response = await fetch(process.env.PUBLIC_URL+'/multilingual_v2.json');
            let lingoData = await response.json();
            setLingo(data.lang && lingoData[data.lang] ? lingoData[data.lang] : lingoData['default']);
        } catch (error){
            console.log('Feil ved lasting av multilingual_v2.json', error);
        }
    }

    const initGA = () => {
        console.log(data.googleAnalyticsTag)
        if(data?.googleAnalyticsTag){
            ReactGA.initialize(data.googleAnalyticsTag);
            console.log(ReactGA)
        }
    }

    const fetchConfig = async () => {

        // Skal hente inn config.json fra riktig side
        // hvis det er localhost så skal den hentes fra sbsdev.catchmedia.no
        // Ellers skal den finne ut hva slags side det er ut ifra window.location || window.location.hostname
        // Starte med å fjerne noe i package.json som heter proxy for å få den til å hente inn riktig sted 

            let res = await axios.post('api/', {
                action: 'api-fetchConfig',
                contextKey
            });

            let configJson = await JSON.parse(res.data.json);
            setData(configJson);
    }

    const configTypeBook = () => {
        // Bake om config til å være et oppslagsbakeverk
        const applicationsArr = data.applications;
        const applications = applicationsArr.map(applications => {
            let temp = {
                type: applications.type
            }
            if(applications.application && applications.application.sections){
                let sections = applications.application.sections;
                let sectionArr = sections.filter(section => section.type === 'form' || section.type === 'user_info');
                let innerEl = [];
                sectionArr.map((section) => {
                    section.forms.map((el) => {
                        el.elements.map((field) => {
                            if(field.type !== 'calculate'){
                                let helper = {}
                                helper.name = field.name;
                                helper.type = field.type;
                                innerEl.push(helper);
                            }
                            if(field.type === 'repeater'){
                                field.fields.map((repeaterFields) => {
                                    
                                    repeaterFields.map((repeaterField) => {
                                        let helper = {}
                                        helper.name = repeaterField.name;
                                        helper.type = repeaterField.type;
                                        innerEl.push(helper);
                                    })
                                    // let helper = {};
                                    // helper.name = repeaterField.name;
                                    // helper.type = repeaterField.type;
                                    // innerEl.push(helper);
                                })
                            }
                        })
                    })
                })
                temp.fields = innerEl;
            }
            return temp;
        })
        return applications;
    }

    return <ConfigContext.Provider value={{
        data,
        lingo,
        typeBook,
        baseURL,
        hasFinalReport: (type) => {
            if(data){
                const application = data.applications.filter(t => t.type === type)[0]
                if(application && application.finalReport){
                    return true;
                }

                return false
            }
        }
    }}>{baseURL && data && lingo ? children : <h1>Laster</h1>}</ConfigContext.Provider>
}